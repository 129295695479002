@import "./assets/css/material-dashboard.css" ;
@import "./assets/css/nucleo-icons.css" ;
@import "./assets/css/nucleo-svg.css" ;

.table thead th{
    color: #000 !important;
}
.dataTables_wrapper .mdb-datatable-filter{
    width: 417px!important;
}
.modal-open{
    overflow: visible !important;
}
/*.modal-backdrop.show{
    display: none !important;
}*/



