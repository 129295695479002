.card {
  border: 1px solid rgb(240, 240, 240);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin-top: 3px;
  margin-bottom: 3px;
  color: black;
}

.menu-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;
}

.card-body button.rejeter {
  border: solid red 1px;
  margin-right: 20px;
}


.card-body button.matcher {
  background-color: #ff8800;
  color: white;
}